import { Checkbox, FormControlLabel } from "@mui/material";
import { SocketConnectionContext, useCommandListener } from "components";
import { useContext, useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

interface IProps {
    petId: string
}
export const PetMuteButton = (props: IProps) => {
    const con = useContext(SocketConnectionContext);
    const [muted, setMuted] = useState(false);

    useCommandListener("WRITE-MUTE", (data: any) => {
        if (data.pet !== props.petId) {
            return;
        }

        setMuted(data.status === "true");
    }, [props.petId]);

    const send = (mute: boolean) => {
        con?.send("WRITE-MUTE", {
            pet: props.petId,
            status: mute,
        });
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    icon={<MicIcon color="success" />}
                    checkedIcon={<MicOffIcon color="error" />}
                    checked={muted}
                    onChange={(e) => {
                        e.preventDefault();
                        send(e.currentTarget.checked);
                    }}
                />
            }
            label={muted ? "Let it speak again" : "Mute it"}
        />
    );
};