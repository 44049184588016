import {
	Button,
	Card,
	CardContent,
	FormGroup,
	Grid,
	Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
	SocketConnectionContext,
	useCommandListener,
	PetMuteButton,
	PetMuffleButton,
	PetAvatarParameterList,
	PiShockList,
} from "components";
import { useContext, useEffect, useState } from "react";

interface IProps {
	id: string;
	onRemove: (id: string) => void;
}

export const PetName = (props: IProps) => {
	const con = useContext(SocketConnectionContext);
	const [name, setName] = useState("Waiting for pet name...");

	useCommandListener(
		"WRITE-NAME",
		(data: any) => {
			if (data.pet !== props.id) {
				return;
			}
			setName(data.name);
		},
		[props.id]
	);

	useEffect(() => {
		con?.send("READ-NAME", {
			pet: props.id,
		});
	}, [con, props.id]);

	return <>{name}</>;
};

export const Pet = (props: IProps) => {
	const con = useContext(SocketConnectionContext);

	return (
		<Card>
			<CardContent>
				<Stack direction="row" spacing={2}>
					<Typography
						variant="h5"
						component="div"
						style={{ flexGrow: 1 }}
					>
						<PetName {...props} />
					</Typography>
					<Button
						style={{ flexShrink: 0 }}
						onClick={() => {
							con?.send("ADOPT", {
								pet: props.id,
								status: false,
							});
							props.onRemove(props.id);
						}}
						variant="outlined"
					>
						Release
					</Button>
				</Stack>
			</CardContent>
			<CardContent>
				<Typography variant="h6" component="div">
					Control
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<PetMuteButton petId={props.id} />
					</Grid>
					<Grid item xs={6}>
						<PetMuffleButton petId={props.id} />
					</Grid>
				</Grid>
			</CardContent>
			<CardContent>
				<Typography variant="h6" component="div">
					PiShocks
				</Typography>
				<FormGroup>
					<PiShockList petId={props.id} />
				</FormGroup>
			</CardContent>
			<CardContent>
				<Typography variant="h6" component="div">
					Parameters
				</Typography>
				<FormGroup>
					<PetAvatarParameterList petId={props.id} />
				</FormGroup>
			</CardContent>
		</Card>
	);
};
