import { ThemeProvider } from "@emotion/react";
import { Button, createTheme, CssBaseline, Stack } from "@mui/material";
import "./App.css";
import { ControlPage } from "pages";
import { SocketConnection } from "components";

const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});

function App() {
	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<div style={{ margin: "0 auto", maxWidth: 600, marginTop: "20px" }}>
				<Stack spacing={2}>
					<SocketConnection>
						<ControlPage />
					</SocketConnection>
					<Button
						href="https://discord.com/invite/zEvH29Wa6R"
						target="_blank"
						variant="text"
					>
						Join our discord
					</Button>
				</Stack>
			</div>
		</ThemeProvider>
	);
}

export default App;
