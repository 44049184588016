import {
	Alert,
	Button,
	Grid,
	Slider,
	Typography,
} from "@mui/material";
import {
	Preloader,
	SocketConnectionContext,
	useCommandListener,
} from "components";
import { useCallback, useContext, useEffect, useState } from "react";

interface IPiShock {
	index: number;
	name: string;
}
interface IProps {
	petId: string;
}
export const PiShockList = (props: IProps) => {
	const con = useContext(SocketConnectionContext);
	const [pishocks, setPishock] = useState<IPiShock[]>();
	const [duration, setDuration] = useState<number>(1);
	const [intensity, setIntensity] = useState<number>(1);

	useEffect(() => {
		con?.send("READ-PISHOCKS", {
			pet: props.petId,
		});
	}, [con, props.petId]);

	useCommandListener(
		"WRITE-PISHOCK",
		(data: any) => {
			if (data.pet !== props.petId) {
				return;
			}

			setPishock((c) => {
				if (c === undefined) {
					return [{ index: data.index, name: data.name }];
				}

				if (c.find((x) => x.index === data.index) !== undefined) {
					return c;
				}

				return [...c, { index: data.index, name: data.name }];
			});
		},
		[props.petId]
	);

	console.log("pishocks", pishocks);

	const beepCommand = useCallback(
		(index: number) => {
			con?.send("WRITE-PISHOCK-ZAP", {
				pet: props.petId,
				index: index,
				mode: "beep",
				duration: duration,
			});
		},
		[con, props.petId, duration]
	);

	const shockCommand = useCallback(
		(index: number) => {
			con?.send("WRITE-PISHOCK-ZAP", {
				pet: props.petId,
				index: index,
				mode: "shock",
				duration: duration,
				intensity: intensity,
			});
		},
		[con, props.petId, duration, intensity]
	);

	const vibrateCommand = useCallback(
		(index: number) => {
			con?.send("WRITE-PISHOCK-ZAP", {
				pet: props.petId,
				index: index,
				mode: "vibrate",
				duration: duration,
				intensity: intensity,
			});
		},
		[con, props.petId, duration, intensity]
	);

	if (pishocks === undefined) {
		return <Preloader />;
	}

	if (pishocks.length === 0) {
		return <Alert severity="info">Das Pet hat keine Pishocks</Alert>;
	}

	return (
		<>
			<Typography id="non-linear-slider" gutterBottom>
				Duration: {duration}
			</Typography>
			<Slider
				value={duration}
				max={10}
				onChange={(e, val) => setDuration(val as number)}
			/>
			<Typography id="non-linear-slider" gutterBottom>
				Intensity: {intensity}
			</Typography>
			<Slider
				value={intensity}
				max={100}
				onChange={(e, val) => setIntensity(val as number)}
			/>

			{pishocks.map((p) => (
				<div>
					{p.name}

					<Grid container spacing={2}>
						<Grid item xs={4}>
							<Button onClick={() => beepCommand(p.index)}>
								Beep
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button onClick={() => vibrateCommand(p.index)}>
								Vibrate
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button onClick={() => shockCommand(p.index)}>
								Shock
							</Button>
						</Grid>
					</Grid>
				</div>
			))}
		</>
	);
};
