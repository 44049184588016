import { Box, CircularProgress } from "@mui/material";

interface IProps {
	text?: string;
}
export const Preloader = (props: IProps) => {
	return (
		<Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
			<CircularProgress />
			<div>{props.text}</div>
		</Box>
	);
};
