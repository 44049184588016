import { Checkbox, FormControlLabel } from "@mui/material";
import { SocketConnectionContext, useCommandListener } from "components";
import { useContext, useState } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

interface IProps {
    petId: string
}

export const PetMuffleButton = (props: IProps) => {
    const con = useContext(SocketConnectionContext);
    const [muffled, setMuffled] = useState(false);

    useCommandListener("WRITE-MUFFLE", (data: any) => {
        if (data.pet !== props.petId) {
            return;
        }
        setMuffled(data.status === "true");
    }, [props.petId]);

    const send = (mute: boolean) => {
        con?.send("WRITE-MUFFLE", {
            pet: props.petId,
            status: mute,
        });
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    icon={<VolumeUpIcon color="success" />}
                    checkedIcon={<VolumeOffIcon color="error" />}
                    checked={muffled}
                    onChange={(e) => {
                        e.preventDefault();
                        send(e.currentTarget.checked);
                    }}
                />
            }
            label={muffled ? "Let it hear something" : "Mute its sound"}
        />
    );
};