import { Checkbox, FormControlLabel } from "@mui/material";
import { SocketConnectionContext, useCommandListener } from "components";
import { useCallback, useContext, useState } from "react";

interface IPetParameterProps {
    petId: string;
    parameterName: string;
}

export const PetAvatarParameter = (props: IPetParameterProps) => {
    const con = useContext(SocketConnectionContext);
    const [parameterValue, setParameterValue] = useState(false);

    useCommandListener("WRITE-AVATAR-PARAMETER", (data: any) => {
        if (data.pet !== props.petId) {
            return;
        }

        if (data.parameterName !== props.parameterName) {
            return;
        }

        setParameterValue(data.parameterValue === "true");
    }, [props.petId]);

    const send = useCallback((newVal: boolean) => {
        if (!con) {
            return;
        }

        con.send("WRITE-AVATAR-PARAMETER", {
            pet: props.petId,
            parameterName: props.parameterName,
            parameterValue: newVal,
        });
    }, [con, props.petId, props.parameterName]);


    return <FormControlLabel
        control={<Checkbox
            checked={parameterValue}
            onChange={(e) => {
                send(!parameterValue);
            }}
        />}
        label={props.parameterName}
    />
}
