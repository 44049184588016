import {
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { SocketConnectionContext, useCommandListener } from "components";
import { useState, useContext } from "react";

interface IProps {
	onAdd: (petId: string) => void;
}
export const AddPet = (props: IProps) => {
	const con = useContext(SocketConnectionContext);
	const [petIdValue, setPetIdValue] = useState("");
	const [loading, setLoading] = useState(false);

	useCommandListener("ADOPT", (data: any) => {
		setLoading(false);
		setPetIdValue("");
		if (data.success === "true") {
			props.onAdd(data.pet);
		}
	}, []);

	return (
		<Card>
			<CardContent>
				<Typography variant="h5" component="div">
					Add new pet
				</Typography>
			</CardContent>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<TextField
							value={petIdValue}
							onChange={(e) =>
								setPetIdValue(e.currentTarget.value)
							}
							label="Control-Key (get it from your pet)"
							style={{ width: "100%" }}
						/>
					</Grid>
					<Grid item xs={4}>
						<Button
							variant="outlined"
							style={{ width: "100%", height: "56px" }}
							disabled={loading}
							onClick={() => {
								if (loading) {
									return;
								}
								setLoading(true);
								con?.send("ADOPT", {
									pet: petIdValue,
									status: true,
								});
							}}
						>
							Adopt pet
						</Button>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
