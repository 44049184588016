import { Preloader } from "components";
import { useState, useEffect, createContext, useMemo, useContext, useRef, useCallback } from "react";

interface ISocketConnection {
	send: (cmd: string, data?: object, pet?: string) => void;
	addEventListener: (listener: (cmd: string, data: any) => void) => void;
	removeEventListener: (listener: (cmd: string, data: any) => void) => void;
}
export const SocketConnectionContext = createContext<
	ISocketConnection | undefined
>(undefined);

export const useCommandListener = (
	commandName: string,
	listener: (data: any) => void,
	deps: React.DependencyList
) => {
	const con = useContext(SocketConnectionContext);

	useEffect(() => {
		if (!con) {
			return () => { };
		}

		const localListener = (cmd: string, data: any) => {
			if (cmd !== commandName) {
				return;
			}
	
			listener(data);
		};
		con.addEventListener(localListener);
		return () => {
			con.removeEventListener(localListener);
		};
	}, [con, ...deps]);
};

interface IProps {
	children: React.ReactNode;
}
export const SocketConnection = (props: IProps) => {
	const socketRef = useRef<WebSocket>();
	const [connected, setConnected] = useState(false);
	const [eventListener, setEventListener] = useState<
		((cmd: string, data: any) => void)[]
	>([]);

	const connect = useCallback(() => {
		const client = new WebSocket("wss://leash.naughty.pet/master");
		socketRef.current = client;

		console.log('Connecting to Pet Server...');

		client.onerror = (e) => console.error(e);

		client.onopen = () => {
			setConnected(true);
			console.log('WebSocket Server connected!');
		};

		client.onclose = () => {
			if (socketRef.current) {
				// Connection failed
				console.log('WebSocket closed by Server');
			} else {
				console.log('WebSocket closed via cleanup');
				return;
			}

			// Parse event code and log
			setConnected(false);
			console.log('Pet Server disconnected');
			setTimeout(connect, 2000);
		};

		setTimeout(() => {
			if (client.readyState != client.CONNECTING) {
				return;
			}
			console.log("Connection failed. Retrying...");
			client.close();
			connect();
		}, 5000);
	}, []);

	useEffect(() => {
		connect();
	}, [connect]);


	useEffect(() => {
		if (!connected || !socketRef?.current) {
			return;
		}

		socketRef.current.onmessage = (ev) => {
			const response = JSON.parse(ev.data);
			console.log(response);
			for (const listener of eventListener) {
				listener.call(this, response.cmd, response.data);
			}
		};
	}, [connected, eventListener, socketRef]);

	useEffect(() => {
		if (!connected || !socketRef?.current) {
			return;
		}

		socketRef.current.onmessage = (ev) => {
			const response = JSON.parse(ev.data);
			console.log(response);
			for (const listener of eventListener) {
				listener.call(this, response.cmd, response.data);
			}
		};
	}, [connected, eventListener, socketRef]);

	const con = useMemo<ISocketConnection | null>(() => {
		if (!socketRef?.current || !connected) {
			return null;
		}

		return {
			send: (cmd: string, data?: object, pet?: string) => {
				const dataToSend = {
					cmd: cmd,
					data: data,
				};
				socketRef?.current?.send(JSON.stringify(dataToSend));
			},
			addEventListener: (listener) => {
				setEventListener((eventListener) => [
					...eventListener,
					listener,
				]);
			},
			removeEventListener: (listener) => {
				setEventListener((eventListener) =>
					eventListener.filter((x) => x !== listener)
				);
			},
		};
	}, [socketRef, connected]);

	if (!con) {
		return <Preloader text="Connecting to Pet Control Server..." />;
	}

	return (
		<SocketConnectionContext.Provider value={con}>
			{props.children}
		</SocketConnectionContext.Provider>
	);
};
