import { Stack } from "@mui/system";
import { Pet, AddPet, SocketConnectionContext } from "components";
import { useEffect, useState, useCallback, useContext } from "react";

export const ControlPage = () => {
	const [petIds, setPetIds] = useState<string[]>([]);
	const con = useContext(SocketConnectionContext);

	useEffect(() => {
		const key = (new URLSearchParams(window.location.search)).get("key");
		if (!con || !key) {
			return;
		}

		con.send("ADOPT", {
			pet: key,
			status: true,
		});
	}, [con]);

	useEffect(() => {
		const storedPets = localStorage.getItem("pets");
		if (storedPets) {
			setPetIds(JSON.parse(storedPets));
		} else {
			setPetIds([]);
		}
	}, []);

	useEffect(() => {
		if (petIds === undefined) {
			return;
		}

		localStorage.setItem("pets", JSON.stringify(petIds));
	}, [petIds]);

	const removePet = useCallback(
		(petId: string) => setPetIds((c) => c?.filter((x) => x !== petId)),
		[]
	);

	return (
		<Stack spacing={2}>
			<AddPet onAdd={(petId: string) => setPetIds([...petIds, petId])} />
			{petIds.map((x) => (
				<Pet key={x} id={x} onRemove={removePet} />
			))}
		</Stack>
	);
};
