import { SocketConnectionContext, useCommandListener, PetAvatarParameter } from "components";
import { useContext, useEffect, useState } from "react";


interface IProps {
	petId: string
}
export const PetAvatarParameterList = (props: IProps) => {
	const con = useContext(SocketConnectionContext);
	const [parameters, setParameters] = useState<string[]>([]);

	useEffect(() => {
		con?.send("READ-AVATAR-PARAMETER-LIST", {
			pet: props.petId
		})
	}, [con, props.petId])

	useCommandListener("WRITE-AVATAR-PARAMETER-LIST", (data: any) => {
		if (data.pet !== props.petId) {
			return;
		}
		setParameters(data.parameters.split(","));

		setTimeout(() => {
			con?.send("READ-AVATAR-PARAMETER-VALUES", {
				pet: props.petId
			})
		}, 2000);
	}, [props.petId]);

	return <>
		{parameters.map(x => <PetAvatarParameter key={x} petId={props.petId} parameterName={x} />)}
	</>
}